import Vue from 'vue'
import Vuex from 'vuex'
import { cloneDeep } from 'lodash'
import { normalizeResponseUnits } from '@/translations'
import { saveAs } from 'file-saver'
import {
  getFastenerInfo,
  isLayerHasFastener,
  calculateGlueSquare,
  isTargetLayerInWedgeExist, isComplexJunction, prepareComplex
} from '@/utils/customFunctions'

import {
  filterFasteners,
  getJunctionType,
  getNameWithCapitalFirstLetter,
  getRandomId,
  isSafari,
  pages
} from '@/utils'
import {
  getContent,
  fetchSlopeMaterials,
  deleteDrawing,
  getSavedProject,
  getPdf,
  getXls,
  checkTNToken,
  getCadDrawings,
  getReplacedMaterial
} from '@/api'
import { uuid } from 'vue-uuid'
import { sendMetrics } from '../api/metrics'
import { calcLabel } from '../utils/sendStatistic'
import WebSocketService from '../api/websocket'
import { getItem, getResults } from '../api'

export function initStore(plugins) {
  const { i18n, router } = plugins

  Vue.use(Vuex)

  class Sectors {
    constructor(sectors, defaultBaseId) {
      this.sectors = sectors
      this.defaultBaseid = defaultBaseId
    }
    get addFastenersInfo() {
      this.sectors.map(p => {
        for (let i = 0; i < p.layers.length; i += 1) {
          for (let n = 0; n < p.layers[i].items.length; n += 1) {
            const item = p.layers[i].items[n]
            if (isLayerHasFastener(item)) {
              item.fasteners.computedInfo = getFastenerInfo(
                i,
                n,
                cloneDeep(p.layers),
                this.defaultBaseid
              )
            }
          }
        }

        for (let i = 0; i < p.junctions.length; i += 1) {
          for (let n = 0; n < p.junctions[i].layers.length; n++) {
            if (p.junctions[i].layers[n]?.items) {
              p.junctions[i].layers[n].items.forEach((item, index, items) => {
                if (item.type === 'mount' && items[index - 1]) {
                  item.id = items[index - 1].id
                }
              })
            }
          }
        }
      })
      return this.sectors
    }
    get prepareWedge() {
      this.sectors.forEach(p => {
        if (!isTargetLayerInWedgeExist(p.layers, 'wedge')) {
          delete p.wedge
        }
        if (
          isTargetLayerInWedgeExist(p.layers, 'wedge') &&
          !isTargetLayerInWedgeExist(p.layers, 'glue')
        ) {
          p.wedge.glue = 0
        } // @TODO проверка не верная
      })
      return this.addFastenersInfo // this.sectors
    }
    get prepareSectorsToApi() {
      return this.prepareWedge // this.sectors
    }
  }

  class Item {
    constructor(item) {
      this.item = item
    }
    get setComputedFastenersRate() {
      const rates = []
      if (this.item.material.fastenersRate) {
        rates.push(this.item.material.fastenersRate)
      }
      if (this.item.computedFastenersRate) {
        rates.push(this.item.computedFastenersRate)
      } else {
        rates.push(0)
      }
      this.item.computedFastenersRate = Math.max(...rates)
      return this.item
    }
  }

  class Layers {
    constructor(layers) {
      this.layers = layers
    }
    get setComputedFastenersRate() {
      for (let i = 0; i < this.layers.length; i += 1) {
        for (let n = 0; n < this.layers[i].items.length; n += 1) {
          const item = this.layers[i].items[n]
          const rates = []
          if (Object.prototype.hasOwnProperty.call(item, 'fasteners')) {
            rates.push(item.fasteners.rate)
          }
          if (Object.prototype.hasOwnProperty.call(item.material, 'fastenersRate')) {
            rates.push(item.material.fastenersRate)
          }
          if (Object.prototype.hasOwnProperty.call(item, 'computedFastenersRate')) {
            rates.push(item.computedFastenersRate)
            item.computedFastenersRate = Math.max(...rates)
          }
        }
      }
      return this.layers
    }
  }

  const store = new Vuex.Store({
    state: {
      sessionToken: null,
      stepNumbers: null,
      TNTokenStatus: false,
      TNToken: null,
      hash: null,
      websocket: null,
      websocketId: null,
      openedTab: null,
      albums: [],
      user: {
        result: null,
        sessionId: null,
        objectId: null
      },
      saveCalculationLink: null,
      result: {
        disclaimer: null,
        note: ''
      },
      publicLink: null,
      isFromLink: false,
      junctionCategories: [],
      junctions: [],
      materialCategories: [],
      systemsCategories: [],
      relatedProducts: null,
      systems: [],
      fasteners: [],
      slopeProducts: [],
      slopeCategories: [],
      slopeFasteners: [],
      slopeFastenersCategories: [],
      layersList: [],
      junctionsLayersList: [],
      drawingId: '',
      sectors: [
        {
          name: '',
          system: '',
          systemType: 'defaultSystem',
          isCustom: false,
          customSystemName: '',
          square: 0,
          multilayerSquare: null,
          junctions: [],
          deletedJunctions: [],
          layers: [],
          uid: 'dm8wgwmb4',
          sectorId: null,
          sectorImg: null,
          junctionsImg: null,
          replacedProducts: []
        }
      ],
      baseTypes: [],
      defaultSector: {
        name: '',
        square: 0,
        multilayerSquare: null,
        junctions: [],
        deletedJunctions: [],
        system: '',
        layers: [],
        systemType: 'defaultSystem',
        customSystemName: '',
        isCustom: false,
        uid: null,
        sectorId: null,
        sectorImg: null,
        junctionsImg: null,
        replacedProducts: []
      },
      defaultCustomSector: {
        name: '',
        square: 0,
        junctions: [],
        system: '',
        layers: [],
        systemType: 'customSystem',
        isCustom: true,
        customSystemName: '',
        uid: null,
        sectorId: null
      },
      defaultJunction: {
        uid: 'l8pncd39a',
        id: null,
        drawingUrl: null,
        count: 0,
        type: 'chiseled',
        layers: [],
        junctionType: 'defaultJunction',
        isCustom: false,
        customJunctionName: '',
        junctionLayerId: null,
        isComplex: false
      },
      defaultCustomJunction: {
        id: null,
        drawingUrl: null,
        count: 0,
        type: 'chiseled',
        layers: [],
        junctionType: 'customJunction',
        isCustom: true,
        customJunctionName: '',
        uid: 'opqz6o756',
        junctionLayerId: null,
        isComplex: false
      },
      defaultFastener: {
        rate: 0,
        selected: 9005
      },
      defaultMountLayer: {
        name: '',
        type: 'mount', // [layer, mount]
        mountRate: 0, // может не быть
        mark: '',
        height: 0,
        basementType: null, // это ид из всех возможных типов найти самому
        selected: 0
      },
      defaultJunctionLayer: {
        materialRate: 0, // может не быть
        mark: '',
        type: 'layer'
      },
      pages: {
        [pages.systems]: {
          isLoading: true
        },
        [pages.result]: {
          isLoading: true
        },
        [pages.wedge]: {
          isLoading: false
        },
        [pages.details]: {
          isLoading: false
        },
        [pages.save]: {
          isLoading: false
        }
      },
      defaultBaseId: 0,
      defaultSystemSlope: {
        slopes: {
          mainSlope: {
            id: null,
            plates: []
          },
          contrSlope: {
            id: null,
            plates: []
          }
        },
        fasteners: [],
        glue: 0,
        square: 0
      }
    },
    mutations: {
      // //////////////////////////////////////STATE/////////////////////////////////////
      SET_DEFAULT_TRANSLATED_FIELDS: (state, payload) => {
        state.sectors[0].name = payload.sectorName
        state.sectors[0].customSystemName = payload.customSystemName
        state.defaultSector.name = payload.sectorName
        state.defaultSector.customSystemName = payload.customSystemName
        state.defaultCustomSector.name = payload.sectorName
        state.defaultCustomSector.customSystemName = payload.customSystemName
        state.defaultJunction.customJunctionName = payload.customJunctionName
        state.defaultCustomJunction.customJunctionName = payload.customJunctionName
        state.defaultMountLayer.name = payload.defaultMountLayerName
      },

      UPDATE_TN_TOKEN_STATUS: (state, TNTokenStatus) => {
        state.TNTokenStatus = TNTokenStatus
      },

      UPDATE_TN_TOKEN: (state, token) => {
        state.TNToken = token
      },
      UPDATE_SESSION_TOKEN: (state, sessionToken) => {
        state.sessionToken = sessionToken
      },
      UPDATE_STEP_NUMBER: (state, stepNumber) => {
        state.stepNumbers = stepNumber
      },
      UPDATE_PAGE_LOADING_STATUS: (state, { page, status }) => {
        state.pages[pages[page]].isLoading = status
      },
      UPDATE_USER_INFO: (state, payload) => {
        state.user = cloneDeep(payload)
      },
      UPDATE_USER_HASH: (state, payload) => {
        state.user.hash = payload
      },
      UPDATE_USER_LINK: (state, payload) => {
        state.user.link = `${payload}${state.user.hash}`
      },
      UPDATE_HASH: (state, payload) => {
        state.hash = payload
      },
      UPDATE_CONTENT: (state, payload) => {
        const relatedProducts = payload.relatedProducts.map((item) => {
          if (item.status === undefined) {
            item.status = true
          }
          item.id = item.id.toString()

          return item
        })

        Vue.set(state, 'systems', Object.freeze(cloneDeep(payload.systems)))
        Vue.set(state, 'systemsCategories', Object.freeze(cloneDeep(payload.systemsCategories)))
        Vue.set(state, 'baseTypes', Object.freeze(cloneDeep(payload.baseTypes)))
        Vue.set(state, 'fasteners', Object.freeze(cloneDeep(payload.mounting)))
        Vue.set(state, 'junctions', Object.freeze(cloneDeep(payload.junctions)))
        Vue.set(state, 'relatedProducts', Object.freeze(cloneDeep(relatedProducts)))
        Vue.set(state, 'junctionsCategories', Object.freeze(cloneDeep(payload.junctionsCategories)))
        Vue.set(state.defaultCustomSector, 'layers', cloneDeep(payload.defaultSystem.layers))
        Vue.set(state, 'junctionsLayersList', Object.freeze(cloneDeep(payload.junctionsLayers)))
        Vue.set(state, 'layersList', Object.freeze(cloneDeep(payload.systemsLayers)))
        Vue.set(state, 'materialCategories', Object.freeze(cloneDeep(payload.materialCategories)))
        Vue.set(state, 'albums', Object.freeze(cloneDeep(payload.albums)))
        state.defaultBaseId = Object.freeze(payload.defaultBaseId)
        state.defaultMountLayer.basementType = payload.defaultBaseId
        const [firstAvailableFastener] = filterFasteners(
          payload.defaultBaseId,
          'junction',
          payload.mounting
        )
        state.defaultMountLayer.selected = firstAvailableFastener?.id
      },
      UPDATE_SLOPE_CONTENT: (state, payload) => {
        Vue.set(state, 'slopeProducts', cloneDeep(payload.slope.products))
        Vue.set(state, 'slopeCategories', cloneDeep(payload.slope.categories))
        Vue.set(state, 'slopeFasteners', cloneDeep(payload.fasteners.products))
        Vue.set(state, 'slopeFastenersCategories', cloneDeep(payload.fasteners.categories))
        Vue.set(state, 'slopeDisclaimer', cloneDeep(payload.disclaimer))
      },
      UPDATE_STATE_ARRAY: (state, payload) => {
        Vue.set(state, [payload.param], cloneDeep(payload.array))
      },
      RESET_SECTORS: state => {
        if (state.TNToken) {
          state.TNToken = null
        }
        state.sectors.splice(0, state.sectors.length)
        state.sectors.push(cloneDeep(state.defaultSector))
        state.result = {}
      },
      UPDATE_RESULT: (state, payload) => {
        Vue.set(state.result, 'total', payload.total)
        Vue.set(state.result, 'sectors', payload.sectors)
      },
      SET_SAVED_DATA: (state, payload) => {
        Vue.set(state, 'sectors', JSON.parse(payload.data.sectors))

        const result = JSON.parse(payload.data.result)
        const normalizedResponseUnits = normalizeResponseUnits(result, state.junctions, state.sectors, i18n)

        normalizedResponseUnits.total.materials.forEach((material, i) => {
          if (material.junction) {
            material.junction.forEach((j, index) => {
              j.name = getNameWithCapitalFirstLetter(
                result.sectors[i].sector.junctions[index].originalName
              )
              j.type = getJunctionType(j, state.junctions)
              j.unit =
                  j.type === 'linear'
                    ? i18n.messages[payload.lang].message.units.mp
                    : i18n.messages[payload.lang].message.units.pc
            })
          }
        })

        Vue.set(state.result, 'total', normalizedResponseUnits.total)
        Vue.set(state.result, 'sectors', normalizedResponseUnits.sectors)
        state.isFromLink = true

        const resultRelatedProductsIds = state.result.total?.computation.map(item => item.id)
        state.relatedProducts.forEach((item) => {
          if (resultRelatedProductsIds.includes(parseInt(item.id))) {
            item.status = true
          }
        })
      },
      SET_EXTENDED_SAVED_DATA: (state, { result, sectors }) => {
        Vue.set(state.result, 'total', result.total)
        Vue.set(state.result, 'sectors', result.sectors)
        Vue.set(state, 'sectors', sectors)
      },
      // //////////////////////////////////////STATE//////////////////////////////////////

      // //////////////////////////////////////SYSTEM//////////////////////////////////////

      UPDATE_SECTOR_SYSTEM: (state, payload) => {
        const sectorId = state.sectors[payload.index].sectorId
        Vue.set(state.sectors, [payload.index], cloneDeep(state.defaultSector))
        state.sectors[payload.index].system = payload.systemId
        state.sectors[payload.index].uid = getRandomId()
        state.sectors[payload.index].sectorId = sectorId
        state.sectors[payload.index].replacedProducts = []
        const clonedLayers = cloneDeep(payload.data.layers)
        const refactoredLayers = new Layers(clonedLayers)
        Vue.set(
          state.sectors[payload.index],
          'layers',
          cloneDeep(refactoredLayers.setComputedFastenersRate)
        )
        state.defaultSystemSlope.slopes.mainSlope.id = null
        state.defaultSystemSlope.slopes.contrSlope.id = null
        if (Object.prototype.hasOwnProperty.call(payload.data, 'slope')) {
          if (Object.prototype.hasOwnProperty.call(payload.data.slope, 'mainSlope')) {
            state.defaultSystemSlope.slopes.mainSlope.id = payload.data.slope.mainSlope
          } else {
            state.defaultSystemSlope.slopes.mainSlope.id = null
          }
          if (Object.prototype.hasOwnProperty.call(payload.data.slope, 'contrSlope')) {
            state.defaultSystemSlope.slopes.contrSlope.id = payload.data.slope.contrSlope
          } else {
            state.defaultSystemSlope.slopes.contrSlope.id = null
          }
        }
      },
      CREATE_CUSTOM_SYSTEM: (state, payload) => {
        const sectorId = state.sectors[payload].sectorId
        Vue.set(state.sectors, payload, cloneDeep(state.defaultCustomSector))
        state.sectors[payload].sectorId = sectorId
        state.defaultSystemSlope.slopes.mainSlope.id = null
        state.defaultSystemSlope.slopes.contrSlope.id = null
      },
      RESTORE_SYSTEM_TO_DEFAULT: (state, payload) => {
        state.sectors[payload.sectorIndex].isCustom = false
        state.sectors[payload.sectorIndex].systemType = 'defaultSystem'
        const clonedLayers = cloneDeep(payload.data.layers)
        const refactoredLayers = new Layers(clonedLayers)
        state.sectors[payload.sectorIndex].layers = cloneDeep(
          refactoredLayers.setComputedFastenersRate
        )
        state.defaultSystemSlope.slopes.mainSlope.id = null
        state.defaultSystemSlope.slopes.contrSlope.id = null
        if (Object.prototype.hasOwnProperty.call(payload.data, 'slope')) {
          if (Object.prototype.hasOwnProperty.call(payload.data.slope, 'mainSlope')) {
            state.defaultSystemSlope.slopes.mainSlope.id = payload.data.slope.mainSlope
          } else {
            state.defaultSystemSlope.slopes.mainSlope.id = null
          }
          if (Object.prototype.hasOwnProperty.call(payload.data.slope, 'contrSlope')) {
            state.defaultSystemSlope.slopes.contrSlope.id = payload.data.slope.contrSlope
          } else {
            state.defaultSystemSlope.slopes.contrSlope.id = null
          }
        }
      },
      UPDATE_SECTOR_SYSTEM_LAYERS_ORDER: (state, payload) => {
        state.sectors[payload.sectorIndex].layers = payload.value
      },
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_VALUE: (state, payload) => {
        const { sectorIndex, layerIndex, itemIndex, value } = payload

        state.sectors[sectorIndex].layers[layerIndex].items[itemIndex].material.value = value
      },
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_VALUE: (
        state,
        { sectorIndex, layerIndex, itemIndex, multilayerIndex, value }
      ) => {
        state.sectors[sectorIndex].layers[layerIndex].items[itemIndex].materials[multilayerIndex].value = value
      },
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_SQUARE: (
        state,
        { sectorIndex, layerIndex, itemIndex, multilayerIndex, square }
      ) => {
        state.sectors[sectorIndex].layers[layerIndex].items[itemIndex].materials[multilayerIndex].square = square

        let multilayerSquare = 0

        state.sectors[sectorIndex].layers.forEach((layer) => {
          layer.items.forEach((i) => {
            if (i.materials) {
              const layerMultilayerSquare = i.materials.reduce((sum, cur) => {
                return cur.square ? sum + cur.square : 0
              }, 0)
              multilayerSquare += layerMultilayerSquare
            }
          })
        })

        state.sectors[sectorIndex].multilayerSquare = multilayerSquare
      },
      UPDATE_SECTOR_SYSTEM_LAYER_FASTENER_PARAM: (
        state,
        { sectorIndex, layerIndex, itemIndex, param, value }
      ) => {
        state.sectors[sectorIndex].layers[layerIndex].items[itemIndex].fasteners[param] = value
      },
      UPDATE_SECTOR_SYSTEM_LAYER_COMPUTED_FASTENER: (
        state,
        { sectorIndex, layerIndex, itemIndex, value }
      ) => {
        state.sectors[sectorIndex].layers[layerIndex].items[itemIndex].computedFastenersRate = value
      },
      UPDATE_SECTOR_SYSTEM_LAYER_ENABLE_STATUS: (state, payload) => {
        state.sectors[payload.sectorIndex].layers[payload.layerIndex].isEnabled = payload.value
      },
      ADD_FASTENER_TO_LAYER: (state, payload) => {
        state.defaultFastener.selected = payload.fastenerId
        if (typeof payload.fastenerRate !== 'undefined') {
          state.defaultFastener.rate = payload.fastenerRate
        } else {
          state.defaultFastener.rate = 0
        }
        const clonedItem = cloneDeep(
          state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex]
        )
        Vue.set(clonedItem, 'fasteners', cloneDeep(state.defaultFastener))
        const refactoredItem = new Item(clonedItem)
        Vue.set(
          state.sectors[payload.sectorIndex].layers[payload.layerIndex].items,
          [payload.itemIndex],
          cloneDeep(refactoredItem.setComputedFastenersRate)
        )
      },
      SET_COMPUTED_HEIGHT_FASTENER: (state, payload) => {
        const target =
          state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex].fasteners
        if (Object.prototype.hasOwnProperty.call(target, 'computedFastenerHeight')) {
          target.computedFastenerHeight = payload.height
        } else {
          Vue.set(target, 'computedFastenerHeight', payload.height)
        }
      },
      REMOVE_FASTENER_FROM_LAYER: (state, payload) => {
        Vue.delete(
          state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex],
          'fasteners'
        )
      },
      ADD_PARENT_LAYER_TO_SYSTEM: (state, payload) => {
        const addedLayer = cloneDeep(state.layersList.find(p => p.id === payload.layerId))
        addedLayer.items.splice(0, addedLayer.items.length)
        state.sectors[payload.sectorIndex].layers.push(addedLayer)
      },
      ADD_CHILD_LAYER_TO_SYSTEM: (state, payload) => {
        const target = state.sectors[payload.sectorIndex].layers[payload.layerIndex].items
        target.push(cloneDeep(payload.layer))
        Vue.set(target[target.length - 1], 'material', {})
      },
      ADD_CHILD_LAYER_TO_SYSTEM_FROM_SEARCH: (state, { sectorIndex, layerIndex, childLayer }) => {
        state.sectors[sectorIndex].layers[layerIndex].items.push(
          cloneDeep({ ...childLayer, isAdditional: true })
        )
      },
      ADD_PARENT_LAYER_TO_SYSTEM_FROM_SEARCH: (state, { sectorIndex, newParentLayer }) => {
        state.sectors[sectorIndex].layers.push(newParentLayer)
      },
      DELETE_SECTOR_SYSTEM_CHILD_LAYER: (state, payload) => {
        state.sectors[payload.sectorIndex].layers[payload.layerIndex].items.splice(
          payload.itemIndex,
          1
        )
      },
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MATERIAL: (
        state,
        { sectorIndex, layerIndex, itemIndex, material }
      ) => {
        if (!material.isMechanicalFasteners) {
          Vue.delete(state.sectors[sectorIndex].layers[layerIndex].items[itemIndex], 'fasteners')
          Vue.set(
            state.sectors[sectorIndex].layers[layerIndex].items[itemIndex],
            'material',
            cloneDeep(material)
          )
        } else {
          const clonedItem = cloneDeep(state.sectors[sectorIndex].layers[layerIndex].items[itemIndex])
          Vue.set(clonedItem, 'material', cloneDeep(material))
          const refactoredItem = new Item(clonedItem)

          Vue.set(
            state.sectors[sectorIndex].layers[layerIndex].items,
            [itemIndex],
            cloneDeep(refactoredItem.setComputedFastenersRate)
          )
        }
        const target = state.sectors[sectorIndex].layers[layerIndex]
        if (target.isBaseLayer) {
          target.items[itemIndex].baseTypeId = material.baseTypeId
        }
      },
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MATERIALS: (state, { payload }) => {
        const { sectorIndex, layerIndex, itemIndex, material } = payload
        const item = state.sectors[sectorIndex].layers[layerIndex].items[itemIndex]
        if (!item.materials) {
          Vue.set(item, 'materials', [])
        }
        item.materials.push(material)
      },
      DELETE_SECTOR_SYSTEM_LAYER_ITEM_MATERIALS: (state, { payload }) => {
        const { sectorIndex, layerIndex, itemIndex, index } = payload
        state.sectors[sectorIndex].multilayerSquare -=
          state.sectors[sectorIndex].layers[layerIndex].items[itemIndex].materials[index].square
        state.sectors[sectorIndex].layers[layerIndex].items[itemIndex].materials.splice(index, 1)
      },
      DELETE_SECTOR_SYSTEM_MULTILAYER_ITEMS_MATERIALS: (state, { payload }) => {
        const { sectorIndex, layerIndex, itemIndex } = payload
        state.sectors[sectorIndex].layers[layerIndex].items[itemIndex].materials = null
        state.sectors[sectorIndex].multilayerSquare = null
      },
      UPDATE_SECTOR_SYSTEM_BASE_LAYER_TYPE: (state, payload) => {
        state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex].id = payload.id
        state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex].name = payload.name
      },
      UPDATE_SECTOR_WEDGE_PLATE_COUNT: (state, { sectorIndex, slopeType, plateIndex, count }) => {
        state.sectors[sectorIndex].wedge.slopes[slopeType].plates[plateIndex].count = count
      },
      UPDATE_SECTOR_WEDGE_MATERIAL: (state, payload) => {
        const target = state.sectors[payload.sectorIndex].wedge.slopes[payload.slopeType]
        const slopes = state.sectors[payload.sectorIndex].wedge.slopes
        target.id = payload.id
        Vue.set(target, 'plates', cloneDeep(payload.plates))
        state.sectors[payload.sectorIndex].wedge.glue = calculateGlueSquare(
          slopes.contrSlope.plates,
          slopes.mainSlope.plates
        )
      },
      UPDATE_SECTOR_WEDGE_GLUE: (state, payload) => {
        state.sectors[payload.sectorIndex].wedge.glue = payload.value
      },
      UPDATE_SECTOR_WEDGE_PARAM: (state, { sectorIndex, param, value }) => {
        state.sectors[sectorIndex].wedge[param] = value
      },
      ADD_WEDGE_FASTENER: (state, payload) => {
        const newFastener = { id: payload.id, count: 1 }
        state.sectors[payload.sectorIndex].wedge.fasteners.push(newFastener)
      },
      DELETE_ALL_WEDGE_FASTENERS: (state, payload) => {
        state.sectors[payload.sectorIndex].wedge.fasteners = []
      },
      UPDATE_WEDGE_FASTENER: (state, payload) => {
        state.sectors[payload.sectorIndex].wedge.fasteners[payload.fastenerIndex].id = payload.id
      },
      UPDATE_WEDGE_FASTENER_COUNT: (state, payload) => {
        state.sectors[payload.sectorIndex].wedge.fasteners[payload.fastenerIndex].count =
          payload.value
      },
      UPDATE_WEDGE_FASTENERS_ORDER: (state, payload) => {
        state.sectors[payload.sectorIndex].wedge.fasteners = payload.value
      },
      IMPORT_WEDGE_PRODUCTS: (state, { sectorIndex, products }) => {
        const defaultSlopes = cloneDeep(state.defaultSystemSlope.slopes)
        state.sectors[sectorIndex].wedge.slopes = Object.assign(defaultSlopes, { ...products.slopes })
        state.sectors[sectorIndex].wedge.fasteners = []
        if (products.fasteners) {
          state.sectors[sectorIndex].wedge.fasteners = cloneDeep(products.fasteners)
        }
        if (products.sectorsCount) {
          state.sectors[sectorIndex].wedge.sectorsCount = cloneDeep(products.sectorsCount)
        }
        if (products.square) {
          state.sectors[sectorIndex].wedge.square = products.square
        }
        if (products.layersSquare) {
          state.sectors[sectorIndex].wedge.glue = products.layersSquare
        }
      },

      EXPAND_LAYERS: (state, payload) => {
        for (let i = 0; i < state.sectors[payload.sectorIndex].layers.length; i += 1) {
          Vue.set(state.sectors[payload.sectorIndex].layers[i], 'expanded', false)
        }
        const target = state.sectors[payload.sectorIndex].layers[payload.layerIndex]
        Vue.set(target, 'expanded', payload.value)
      },
      COLLAPSE_LAYERS: (state, sectorIndex) => {
        for (let i = 0; i < state.sectors[sectorIndex].layers.length; i += 1) {
          Vue.set(state.sectors[sectorIndex].layers[i], 'expanded', false)
        }
      },
      // //////////////////////////////////////SECTOR//////////////////////////////////////
      ADD_NEW_SECTOR: state => {
        const title = state.defaultSector.name.replaceAll(/\s[0-9]\sen|\s[0-9]/g, '')

        state.defaultSector.name = `${title} ${state.sectors.length + 1}`
        state.defaultSector.uid = getRandomId()
        state.defaultCustomSector.name = `${title} ${state.sectors.length + 1}`
        state.defaultCustomSector.uid = getRandomId()
        state.sectors.push(cloneDeep(state.defaultSector))
      },
      DELETE_SECTOR: (state, payload) => {
        state.sectors.splice(payload, 1)
      },
      DELETE_LAST_SECTOR: (state, payload) => {
        state.sectors.push(cloneDeep(state.defaultSector))
        state.sectors.splice(payload, 1)
      },
      UPDATE_SECTOR_PARAM: (state, payload) => {
        state.sectors[payload.index][payload.param] = payload.value
      },
      ADD_SLOPE_TO_SECTOR: (state, payload) => {
        const clonedSlopes = cloneDeep(state.defaultSystemSlope)
        const slopes = clonedSlopes.slopes
        for (let key in slopes) {
          if (Object.prototype.hasOwnProperty.call(slopes, key)) {
            if (slopes[key].id !== null) {
              const currentPlates = cloneDeep(
                state.slopeProducts.find(p => p.id === slopes[key].id).plates
              )
              currentPlates.map(p => Vue.set(p, 'count', 0))
              Vue.set(slopes[key], 'plates', currentPlates)
            }
          }
        }
        Vue.set(state.sectors[payload], 'wedge', cloneDeep(clonedSlopes))
      },
      // //////////////////////////////////////JUNCTION////////////////////////////////////
      UPDATE_SECTOR_JUNCTION_LAYERS_ORDER: (state, payload) => {
        state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].layers = payload.value
      },
      DELETE_SECTOR_JUNCTION_CHILD_LAYER: (
        state,
        { sectorIndex, junctionIndex, layerIndex, itemIndex }
      ) => {
        state.sectors[sectorIndex].junctions[junctionIndex].layers[layerIndex].items.splice(
          itemIndex,
          1
        )
      },
      RESTORE_JUNCTION_TO_DEFAULT: (state, payload) => {
        state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].isCustom = false
        state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].junctionType = 'defaultJunction'
        state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].type = payload.type
        state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].count = 0
        state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].layers = cloneDeep(
          payload.junctionLayers
        )
      },
      CREATE_CUSTOM_JUNCTION: (state, { sectorIndex, replacementJunctionUid }) => {
        state.defaultCustomJunction.uid = getRandomId()
        if (replacementJunctionUid) {
          const replacementJunctionIndex = state.sectors[sectorIndex].junctions.findIndex(
            j => j.uid === replacementJunctionUid
          )
          Vue.set(
            state.sectors[sectorIndex].junctions,
            [replacementJunctionIndex],
            cloneDeep(state.defaultCustomJunction)
          )
        } else {
          state.sectors[sectorIndex].junctions.push(cloneDeep(state.defaultCustomJunction))
        }
      },
      ADD_JUNCTION_TO_SECTOR: (
        state,
        { sectorIndex, junctionId, junctionType, layers, groupId, replacementJunctionUid, variables }
      ) => {
        const isComplex = isComplexJunction(state.junctions, junctionId)

        state.defaultJunction.uid = getRandomId()
        state.defaultJunction.id = junctionId
        state.defaultJunction.type = junctionType
        state.defaultJunction.isComplex = isComplex
        state.defaultJunction.count = isComplex ? 1 : 0
        Vue.set(state.defaultJunction, 'layers', cloneDeep(isComplex
          ? prepareComplex(layers, state.sectors[sectorIndex])
          : layers)
        )
        Vue.set(state.defaultJunction, 'variables', cloneDeep(variables))
        if (groupId !== null) {
          Vue.set(state.defaultJunction, 'groupId', groupId)
        }
        if (replacementJunctionUid) {
          const replacementJunctionIndex = state.sectors[sectorIndex].junctions.findIndex(
            j => j.uid === replacementJunctionUid
          )
          Vue.set(state.sectors[sectorIndex].junctions, [replacementJunctionIndex], cloneDeep(state.defaultJunction))
        } else {
          state.sectors[sectorIndex].junctions.push(cloneDeep(state.defaultJunction))
        }
        if (state.defaultJunction.groupId) {
          delete state.defaultJunction.groupId
        }
        Vue.set(state.defaultJunction, 'layers', [])
      },
      UPDATE_GROUP_JUNCTION_LAYERS: (state, payload) => {
        Vue.set(
          state.sectors[payload.index].junctions[payload.junctionIndex],
          'layers',
          cloneDeep(payload.layers)
        )
      },
      DELETE_JUNCTION: (state, payload) => {
        const junctionLayerId = state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].junctionLayerId
        if (junctionLayerId) {
          state.sectors[payload.sectorIndex].deletedJunctions.push({
            junctionLayerId: junctionLayerId
          })
        }

        state.sectors[payload.sectorIndex].junctions.splice(payload.junctionIndex, 1)
      },
      UPDATE_JUNCTION_PARAM: (state, { index, junctionIndex, param, value }) => {
        state.sectors[index].junctions[junctionIndex][param] = value
      },
      UPDATE_JUNCTION_LAYER_ITEM_VALUE: (
        state,
        { sectorIndex, junctionIndex, layerIndex, itemIndex, value }
      ) => {
        const junction = state.sectors[sectorIndex].junctions[junctionIndex]
        const item = junction.layers[layerIndex].items[itemIndex]

        if (junction.isComplex) {
          item.calcValue = value
        }

        item.material.value = value
      },
      UPDATE_JUNCTION_LAYER_MATERIAL_PARAM: (
        state,
        { sectorIndex, junctionIndex, layerIndex, itemIndex, param, value }
      ) => {
        state.sectors[sectorIndex].junctions[junctionIndex].layers[layerIndex].items[itemIndex][param] = value
      },
      UPDATE_JUNCTION_LAYER_ITEM_MATERIAL: (
        state,
        { sectorIndex, junctionIndex, layerIndex, itemIndex, material }
      ) => {
        Vue.set(
          state.sectors[sectorIndex].junctions[junctionIndex].layers[layerIndex].items[itemIndex],
          'material',
          cloneDeep(material)
        )
      },
      UPDATE_JUNCTION_FASTENER_SELECTED: (
        state,
        { sectorIndex, junctionIndex, layerIndex, itemIndex, id }
      ) => {
        state.sectors[sectorIndex].junctions[junctionIndex].layers[layerIndex].items[itemIndex].selected = id
      },
      UPDATE_JUNCTION_MOUNT_LAYER_PARAM: (
        state,
        { sectorIndex, junctionIndex, layerIndex, itemIndex, param, value }
      ) => {
        state.sectors[sectorIndex].junctions[junctionIndex].layers[layerIndex].items[itemIndex][param] = value
      },
      ADD_PARENT_LAYER_TO_JUNCTION: (state, payload) => {
        const addedLayer = cloneDeep(state.junctionsLayersList?.find(p => p.id === payload.layerId))
        if (addedLayer?.items) {
          addedLayer.items.splice(0, addedLayer.items.length)
          state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].layers.push(addedLayer)
        }
      },
      ADD_CHILD_LAYER_TO_JUNCTION: (state, { sectorIndex, junctionIndex, layerIndex, layer }) => {
        const target = state.sectors[sectorIndex].junctions[junctionIndex].layers[layerIndex].items
        target.push(cloneDeep(layer))
        Vue.set(target[target.length - 1], 'material', {})
        Vue.set(
          target[target.length - 1],
          'materialRate',
          cloneDeep(state.defaultJunctionLayer.materialRate)
        )
        Vue.set(target[target.length - 1], 'mark', cloneDeep(state.defaultJunctionLayer.mark))
      },
      ADD_CHILD_LAYER_TO_JUNCTION_FROM_SEARCH: (
        state,
        { sectorIndex, junctionIndex, layerIndex, childLayer }
      ) => {
        state.sectors[sectorIndex].junctions[junctionIndex].layers[layerIndex].items.push(cloneDeep(childLayer))
      },
      ADD_PARENT_LAYER_TO_JUNCTION_FROM_SEARCH: (
        state,
        { sectorIndex, junctionIndex, newParentLayer }
      ) => {
        state.sectors[sectorIndex].junctions[junctionIndex].layers.push(newParentLayer)
      },
      ADD_MOUNT_LAYER_TO_JUNCTION: (state, { sectorIndex, junctionIndex, layerIndex }) => {
        state.sectors[sectorIndex].junctions[junctionIndex].layers[layerIndex].items.push(cloneDeep(state.defaultMountLayer))
      },
      UPDATE_DISCLAIMER: (state, { disclaimer }) => {
        state.result.disclaimer = disclaimer
      },
      SAVE_CALCULATE_NOTE: (state, { note }) => {
        state.result.note = note
      },
      ADD_DEFAULT_DISCLAIMER: (state, { disclaimer }) => {
        state.result.disclaimer = disclaimer
      },
      UPDATE_CALCULATION_RESULT(state, payload) {
        state.user.result = payload
      },
      UPDATE_CALCULATION_RESULT_ID(state, payload) {
        if (state.user.result === null) {
          state.user.result = {}
        }
        state.user.result.id = payload
      },
      UPDATE_SOCKET_ID(state, payload) {
        state.websocketId = payload
      },
      UPDATE_OPENED_TAB(state, payload) {
        state.openedTab = payload
      },
      UPDATE_CAD_SETTINGS: (state) => {
        state.websocketId = uuid.v1()
        state.drawingId = ''
      },
      UPDATE_SESSION_ID(state, payload) {
        state.user.sessionId = payload
      },
      UPDATE_OBJECT_ID(state, payload) {
        state.user.objectId = payload
      },
      UPDATE_CALCULATION_STATUS(state, payload) {
        state.saveCalculationLink = payload
      },
      ADD_PUBLIC_LINK(state, payload) {
        state.publicLink = payload
      },
      UPDATE_RELATED_PRODUCTS(state, payload) {
        const { id, status } = payload

        if (!state.relatedProducts) return
        state.relatedProducts = state.relatedProducts.map((item) => {
          if (item.id === id) {
            item.status = status
          }

          return item
        })
      },
      UPDATE_DRAWING(state, payload) {
        if (payload.junctionIndex !== undefined) {
          state.sectors[payload.sectorIndex].junctions[payload.junctionIndex][payload.property] = payload.drawing
        } else {
          state.sectors[payload.sectorIndex][payload.property] = payload.drawing
        }
      },
      ADD_REPLACED_PRODUCTS(state, payload) {
        const { sectorIndex, product } = payload
        const hasReplaced = state.sectors[sectorIndex].replacedProducts
          .find((item) => item.layerId === product.layerId)

        if (hasReplaced) {
          hasReplaced.thickness = product.thickness
          hasReplaced.productId = product.productId
        } else {
          state.sectors[sectorIndex].replacedProducts.push(product)
        }
      }
    },
    getters: {
      getSector: state => index => state.sectors[index],
      getSectorResult: state => index => state.result.sectors[index],
      getItemsByParent: state => index => state.rects[index].items,
      getResult: state => () => state.result,
      getNote: state => state.result.note,
      getHash: state => state.user.hash,
      allPublishedSystems: state => state.systems.filter(system => system.status),
      allPublishedJunctions: state => state.junctions.filter(system => system.status)
    },
    actions: {
      addSocketId({ commit }, socketId) {
        commit('UPDATE_SOCKET_ID', socketId)
      },
      updateOpenedTab({ commit }, tab) {
        commit('UPDATE_OPENED_TAB', tab)
      },
      getItem({ state }, payload) {
        const { type, sectorIndex, junctionIndex, variables } = payload
        const sector = state.sectors[sectorIndex]
        const id = type === 'system' ? sector.system : (sector.junctions[junctionIndex]?.id || junctionIndex)
        const clonedSectors = variables ? new Sectors(cloneDeep(state.sectors), state.defaultBaseId) : null
        const systemData = clonedSectors ? cloneDeep(clonedSectors.prepareSectorsToApi) : null

        return getItem(i18n.locale, type, id, variables, sector.replacedProducts, systemData)
      },
      async openWebsocketConnection({ state, dispatch }, socketId) {
        state.websocket = new WebSocketService(socketId)
        await state.websocket.connect()
      },
      async closeWebsocketConnection({ state }) {
        await state?.websocket?.sendMessage({
          type: 'flat-roof',
          sender: 'flat-roof',
          close: true,
          id: state.websocketId
        })

        await state?.websocket?.close()
        state.websocket = null
      },
      async sendStepToProxy({ state }, payload) {
        let drawingId
        if (state.drawingId) {
          drawingId = state.drawingId
        } else if (state.sectors[payload.sectorIndex]?.drawingId) {
          drawingId = state.sectors[payload.sectorIndex]?.drawingId
        } else {
          drawingId = uuid.v4()
        }
        state.drawingId = drawingId
        const sectorId = state.sectors[payload.sectorIndex].sectorId ? state.sectors[payload.sectorIndex].sectorId : uuid.v4()
        state.sectors[payload.sectorIndex].sectorId = sectorId
        if (payload.currentJunction) {
          state.sectors[payload.sectorIndex].junctions[payload.currentJunction.junctionIndex].junctionLayerId = payload.currentJunction.junctionLayerId
        }

        const junctions = state.sectors[payload.sectorIndex].junctions.map((junction, index) => ({
          junctionIndex: `${index}`,
          junctionLayerId: junction.junctionLayerId,
          junctionType: junction.type
        }))
        const sectors = state.sectors.flatMap((sector, index) => {
          if (state.sectors[index].sectorId) {
            return {
              sectorId: state.sectors[index].sectorId,
              sectorName: state.sectors[index].name,
              sectorIndex: `${index}`,
              junctions: sector.junctions.map((junction, index) => ({
                junctionIndex: `${index}`,
                junctionLayerId: junction.junctionLayerId,
                junctionType: junction.type
              }))
            }
          }
          return []
        })

        const step = {
          type: 'flat-roof',
          sender: 'flat-roof',
          content: {
            drawingId: drawingId,
            name: 'Расчёт материалов ПК',
            step: state.stepNumbers - 1,
            currentSector: {
              sectorId: state.sectors[payload.sectorIndex].sectorId,
              sectorName: state.sectors[payload.sectorIndex].name,
              sectorIndex: `${payload.sectorIndex}`,
              currentJunction: payload.currentJunction,
              junctions: junctions,
              deletedJunctions: state.sectors[payload.sectorIndex].deletedJunctions
            },
            sectors
          },
          close: false,
          id: state.websocketId
        }
        await state?.websocket?.sendMessage(step)
      },
      handleMessage({ state, commit }, payload) {
        state.sectors[payload.sectorIndex].drawingId = payload.drawingId
        state.sectors[payload.sectorIndex].square = payload.sectorSquare
        state.sectors[payload.sectorIndex].sectorImg = payload.sectorSquare !== 0 ? payload.sectorImg : null
        state.sectors[payload.sectorIndex].junctionsImg = payload.junctionsImg

        if (payload.junctionValue) {
          state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].count = payload.junctionValue ? payload.junctionValue : 0
        } else if (payload.junctionValue === 0) {
          if (state.sectors[payload.sectorIndex].junctions[payload.junctionIndex]) {
            state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].count = payload.junctionValue
            state.sectors[payload.sectorIndex].junctionsImg =
              [...state.sectors[payload.sectorIndex].junctionsImg.filter((img, index) => index === payload.junctionIndex)]
          }
        }
      },
      resetUserInfoResults({ commit }) {
        commit('UPDATE_CALCULATION_RESULT', null)
      },
      addSessionId({ commit }, sessionId) {
        commit('UPDATE_SESSION_ID', sessionId)
      },
      addObjectId({ commit }, ObjectId) {
        commit('UPDATE_OBJECT_ID', ObjectId)
      },
      markCalculationAsSaved({ commit }, flag) {
        commit('UPDATE_CALCULATION_STATUS', flag)
      },
      addPublicLink({ commit }, privateLink) {
        const url = new URL(privateLink)
        commit('ADD_PUBLIC_LINK', `${url.origin}/calculators/${calcLabel}/?object=`)
      },
      fetchData({ commit }, lang) {
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: true })
        getContent(lang).then(response => {
          commit('UPDATE_CONTENT', response.data, lang)
          if (response.data.locales.message.store) {
            commit('SET_DEFAULT_TRANSLATED_FIELDS', response.data.locales.message.store)
          }
          commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: false })
          commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.systems, status: false })
        })
      },
      fetchSlopeMaterials({ commit }, { lang, sectorIndex }) {
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.wedge, status: true })
        fetchSlopeMaterials(lang).then(response => {
          commit('UPDATE_SLOPE_CONTENT', response.data)
          commit('ADD_SLOPE_TO_SECTOR', sectorIndex)
          commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.wedge, status: false })
        })
      },
      startNewCalculation({ commit }) {
        commit('RESET_SECTORS')
        commit('UPDATE_CALCULATION_RESULT', null)
        commit('UPDATE_CALCULATION_STATUS', null)
        commit('UPDATE_OBJECT_ID', null)
        commit('UPDATE_CAD_SETTINGS')
        router.push('/')
      },
      deleteFiles({ state, commit }, payload, lang) {
        commit('UPDATE_JUNCTION_PARAM', {
          index: payload.sectorIndex,
          junctionIndex: payload.junctionIndex,
          param: 'drawingUrl',
          value: null
        })
        if (!state.isFromLink) {
          deleteDrawing(payload.files, lang)
        }
      },
      saveNote({ commit }, payload) {
        commit('SAVE_CALCULATE_NOTE', {
          note: payload
        })
      },
      addDefaultDisclaimer({ commit }, payload) {
        commit('ADD_DEFAULT_DISCLAIMER', {
          disclaimer: payload
        })
      },
      getResults({ state, commit }, lang) {
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: true })
        const clonedSectors = cloneDeep(state.sectors)
        const preparedSectors = new Sectors(clonedSectors, state.defaultBaseId)
        const relatedProducts = state.relatedProducts
          .filter((product) => product.status)
          .map(product => product.id)

        let link, id
        if (state.user?.result) {
          link = state.user.result.link
          id = state.user.result.id
        }
        const forProfileData = link || id ? { link, id } : null

        function handleResults(response) {
          if (state.hash !== null) {
            commit('UPDATE_HASH', null)
          }

          if (!forProfileData) commit('UPDATE_CALCULATION_RESULT', response.data?.result)

          const normalizedResponseUnits = normalizeResponseUnits(
            response.data,
            state.junctions,
            state.sectors,
            i18n
          )

          normalizedResponseUnits.total.materials.forEach((material, i) => {
            if (material.junction) {
              material.junction.forEach((j, index) => {
                j.name = getNameWithCapitalFirstLetter(response.data.sectors[i].sector.junctions[index].originalName)
                j.type = getJunctionType(j, state.junctions)

                if (!j.type) {
                  j.type = state.junctions.find(junction => junction.id === j.id)?.type
                }

                j.unit = j.type === 'linear'
                  ? i18n.messages[lang].message.units.mp
                  : i18n.messages[lang].message.units.pc
              })
            }
          })
          commit('UPDATE_RESULT', normalizedResponseUnits)
        }

        function handleDrawings(drawings) {
          if (drawings && drawings?.length) {
            drawings.forEach((drawing) => {
              const sectorIndex = state.sectors.findIndex(sector => drawing.includes(sector.sectorImg) && !drawing.includes('junction'))
              if (sectorIndex !== -1) {
                commit('UPDATE_DRAWING', { sectorIndex, property: 'sectorImg', drawing })

                state.sectors[sectorIndex].junctions.forEach((junction, junctionIndex) => {
                  const junctionDrawings = drawings.filter(item => item.includes(junction.junctionLayerId))
                  if (junctionDrawings && junctionDrawings.length) {
                    junctionDrawings.forEach((junctionDrawing) => {
                      if (junctionDrawing.includes('single')) {
                        commit('UPDATE_DRAWING', { sectorIndex, junctionIndex, property: 'junctionImg', drawing: junctionDrawing })
                      } else {
                        commit('UPDATE_DRAWING', { sectorIndex, junctionIndex, property: 'junctionBaseImg', drawing: junctionDrawing })
                      }
                    })
                  }
                })
              }
            })
          }
        }

        const handleResultWithoutDrawings = () => {
          getResults(
            lang,
            cloneDeep(preparedSectors.prepareSectorsToApi),
            forProfileData,
            relatedProducts.length ? relatedProducts : null
          ).then((response) => {
            handleResults(response)
            commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: false })
          })
        }

        if (state.sectors.some(sector => sector.sectorId)) {
          const allDrawing = state.sectors.map(sector => {
            return [sector.sectorImg,
              ...(sector.junctionsImg || []).map((img) => img.junctionImg),
              ...(sector.junctionsImg || []).map((img) => img.junctionBaseImg)]
          })
            .flat()
            .filter(drawing => drawing !== null && drawing !== undefined)

          if (allDrawing.length) {
            Promise.all([
              getResults(
                lang,
                cloneDeep(preparedSectors.prepareSectorsToApi),
                forProfileData,
                relatedProducts.length ? relatedProducts : null
              ),
              getCadDrawings(allDrawing)
            ]).then(([response, drawings]) => {
              handleResults(response)
              handleDrawings(drawings.data)
              commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: false })
            })
          } else {
            handleResultWithoutDrawings()
          }
        } else {
          handleResultWithoutDrawings()
        }
      },
      addMultilayer({ commit }, payload) {
        commit('UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MATERIALS', { payload })
      },
      removeMultilayerItem({ commit }, payload) {
        commit('DELETE_SECTOR_SYSTEM_LAYER_ITEM_MATERIALS', { payload })
      },
      deleteMultilayerItems({ commit }, payload) {
        commit('DELETE_SECTOR_SYSTEM_MULTILAYER_ITEMS_MATERIALS', { payload })
      },
      async getSavedProject({ commit }, { lang, hash, page }) {
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[page], status: true })
        commit('UPDATE_CALCULATION_RESULT_ID', hash)

        Promise.all([getContent(lang), fetchSlopeMaterials(lang), getSavedProject(hash, lang)])
          .then(response => {
            const [content, slopeProducts, savedProject] = response
            commit('UPDATE_CONTENT', content.data)
            commit('UPDATE_SLOPE_CONTENT', slopeProducts.data)
            commit('SET_SAVED_DATA', { data: savedProject.data, lang })
            commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[page], status: false })
          })
          .catch(() => {
            router.push('/404')
          })
      },
      getPdf({ state, commit }, payload) {
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[payload.page], status: true })
        getPdf(
          payload,
          state.sectors,
          state.result,
          state.junctions,
          state.albums,
          state.user.result?.id,
          state.albums,
          state.publicLink ? `${state.publicLink}${state.user.result.id}` : ``,
          state.relatedProducts.filter(item => item.status).map(item => parseInt(item.id))
        ).then(response => {
          const file = new Blob([response.data], { type: 'application/pdf' })
          // Build a URL from the file
          const fileURL = URL.createObjectURL(file)
          commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[payload.page], status: false })

          if (isSafari()) {
            const fileName = `${payload.fileName}.pdf`
            saveAs(file, fileName)
          } else {
            // Open the URL on new Window
            window.open(fileURL)
          }
        })
      },
      getXls({ state, commit }, { lang, type, fileName, sectorIndex, junctionIndex, title, page }) {
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[page], status: true })
        // prettier-ignore
        getXls(
          lang,
          type,
          state.result,
          state.sectors,
            state.user.result?.id,
            sectorIndex,
            junctionIndex,
            title,
            state.publicLink ? `${state.publicLink}${state.user.result.id}` : ``,
            state.relatedProducts.filter(item => item.status).map(item => parseInt(item.id))
        ).then(response => {
          const file = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[page], status: false })

          const newFileName = `${fileName}.xlsx`
          saveAs(file, newFileName)
        })
      },
      deleteSectorFromDetailsPage({ state, commit }, { sectorIndex, lang }) {
        commit('DELETE_SECTOR', sectorIndex)
        state.result = {}
        router.push(`/${lang}/result`)
        if (state.sectors.length === 0) {
          state.sectors.push(cloneDeep(state.defaultSector))
          commit('UPDATE_CAD_SETTINGS')
        }
      },
      deleteJunctionFromDetailsPage({ state, commit }, { sectorIndex, junctionIndex, lang }) {
        commit('DELETE_JUNCTION', { sectorIndex, junctionIndex })
        state.result = {}
        router.push(`/${lang}/result`)
      },
      deleteJunctionFromPage({ state, commit }, { sectorIndex, junctionIndex, lang }) {
        const currentJunctions = state.sectors[sectorIndex].junctions
        if (currentJunctions.length === 1) {
          router.push(`/${lang}/junctions/${sectorIndex}`)
          commit('DELETE_JUNCTION', { sectorIndex, junctionIndex })
        } else if (currentJunctions.length - 1 === Number(junctionIndex)) {
          router.push(`/${lang}/system/${sectorIndex}/junction/${junctionIndex - 1}`)
          commit('DELETE_JUNCTION', { sectorIndex, junctionIndex })
        } else {
          commit('DELETE_JUNCTION', { sectorIndex, junctionIndex })
        }
      },
      transformJunctionToCustom(
        { state, commit },
        { sectorIndex, junctionIndex, isSaveDrawing, transformations, originalDrawingUrl }
      ) {
        Object.entries(transformations).forEach(entry => {
          const [param, value] = entry
          commit('UPDATE_JUNCTION_PARAM', {
            index: sectorIndex,
            junctionIndex,
            param,
            value
          })
        })
        if (isSaveDrawing) {
          commit('UPDATE_JUNCTION_PARAM', {
            index: sectorIndex,
            junctionIndex,
            param: 'drawingUrl',
            value: originalDrawingUrl
          })
        }
      },
      updateTNToken({ commit }) {
        commit('UPDATE_TN_TOKEN_STATUS', true)
      },
      checkTNToken({ commit }, token, lang) {
        checkTNToken(token, lang)
          .then(() => {
            commit('UPDATE_TN_TOKEN', token)
          })
          .catch(() => {
            commit('UPDATE_TN_TOKEN_STATUS', true)
          })
      },
      updateStep({ state, commit, dispatch }, stepNumber) {
        if (!state.sessionToken) {
          commit('UPDATE_SESSION_TOKEN', uuid.v1())
        }
        if (stepNumber) {
          commit('UPDATE_STEP_NUMBER', stepNumber)
          dispatch('sendMetrics')
        }
      },
      replaceMaterialFromSystem({ state, commit }, data) {
        const { sectorIndex, layerIndex, itemIndex, productId, thickness } = data
        const sector = state.sectors[sectorIndex]

        const request = {
          products: [ { layerId: sector.layers[layerIndex].items[itemIndex]?.id, productId, thickness } ],
          junctions: sector.junctions.filter(junction => !junction.isCustom).map(junction => junction.id)
        }

        getReplacedMaterial(request).then(result => {
          const replacingMaterials = result.data

          if (replacingMaterials?.length) {
            request.products.forEach((product) => {
              commit('ADD_REPLACED_PRODUCTS', { sectorIndex, product })
            })

            sector.junctions.forEach((junction) => {
              replacingMaterials.forEach((replacingMaterial) => {
                if (junction.id === replacingMaterial.junctionId) {
                  junction.layers.forEach((layer) => {
                    layer.items.forEach((item) => {
                      if (item.id === replacingMaterial.layerId) {
                        Object.entries(item.material).forEach(([key, value]) => {
                          const replacedValue = replacingMaterial.product[key]

                          if (replacedValue !== undefined) {
                            item.material[key] = Array.isArray(replacedValue) ? [...replacedValue] : replacedValue
                          }
                          item.material.allowedOptions = replacingMaterial.product.options
                        })
                      }
                    })
                  })
                }
              })
            })
          } else if (!sector.junctions.length) {
            request.products.forEach((product) => {
              commit('ADD_REPLACED_PRODUCTS', { sectorIndex, product })
            })
          }
        })
      },
      updateSystemLayerMaterialItemValue({ state, commit }, payload) {
        commit('UPDATE_SECTOR_SYSTEM_LAYER_ITEM_VALUE', payload)
      },
      updateDisclaimer({ commit }, payload) {
        commit('UPDATE_DISCLAIMER', {
          disclaimer: payload
        })
      },
      updateRelatedProducts({ commit }, payload) {
        commit('UPDATE_RELATED_PRODUCTS', payload)
      },
      async sendMetrics({ state }) {
        const { sectors, result, sessionToken, stepNumbers, user } = state
        try {
          await sendMetrics(sessionToken, stepNumbers, sectors, result, i18n.locale, user)
        } catch (e) {}
      }
    }
  })

  return Promise.resolve({
    ...plugins,
    store
  })
}
