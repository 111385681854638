// import { cloneDeep } from 'lodash'

export const findJunctionOriginalName = (index, sectorJunctions, allJunctions) => {
  if (typeof index !== 'number') {
    return ''
  }
  const currentJunction = sectorJunctions[index]
  const isJunctionCreatedByUser = currentJunction.isCustom && !currentJunction.id
  const junction = getJunction(currentJunction)
  if (isJunctionCreatedByUser) {
    return currentJunction.customJunctionName.toLowerCase()
  } else {
    const originalJunction = getJunctionById(junction, allJunctions)
    return originalJunction ? originalJunction.toLowerCase() : ''
  }
}

const getJunction = currentJunction => {
  const { id, groupId } = currentJunction
  const isSingle = !Object.prototype.hasOwnProperty.call(currentJunction, 'groupId')
  return isSingle ? { id, isSingle } : { id, groupId, isSingle }
}
const getJunctionById = (junction, allJunctions) => {
  const { id, groupId, isSingle } = junction
  return isSingle
    ? allJunctions.find(j => j.id === id).name // allJunctions.find(j => j.id === id)
    : allJunctions.find(g => g.id === groupId).items.find(j => j.id === id).title // allJunctions.find(g => g.id === groupId).groupName
}

export const filterFasteners = (baseTypeId, placementType, fasteners) => {
  // prettier-ignore
  return baseTypeId === null
    ? fasteners
    : fasteners.filter(fastener => {
      return (
        fastener.baseTypes.includes(baseTypeId) && fastener.placementTypes.includes(placementType)
      )
    })
}

export const isValidNumber = (number, canBeZero = false) =>
  !isNaN(parseFloat(number)) && canBeZero ? number >= 0 : number > 0

export const scrollAndHighLightErrorField = target => {
  const offsetTop = target.getBoundingClientRect().top + document.documentElement.scrollTop
  window.scrollTo(0, offsetTop - 100)
  target.classList.add('v-error')
  setTimeout(() => {
    target.classList.remove('v-error')
  }, 1500)
}

export const prepareAlbums = (currentAlbums, allAlbums) => {
  return [...currentAlbums].map(item => {
    return allAlbums.find(album => album.id === item)
  })
}

export const getRandomId = () => Math.random().toString(36).substr(2, 9)

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export const sliceStr = str => (str ? (str.length >= 46 ? `${str.slice(0, 46)}...` : str) : '')

export const pages = {
  systems: 'systems',
  save: 'save',
  result: 'result',
  details: 'details',
  wedge: 'wedge'
}

export function getNameWithCapitalFirstLetter(name) {
  return name ? name.slice(0, 1).toUpperCase() + name.slice(1, name.length) : ''
}

export function getJunctionType(item, junctions) {
  let junction = junctions.find(j => {
    return j.name
      ? j.name.toLowerCase() === item.name.toLowerCase()
      : j.items.some(i => i.title.toLowerCase() === item.name.toLowerCase())
  })

  if (!junction?.type) {
    junction = junction?.items.find(i => i.title.toLowerCase() === item.name.toLowerCase())
  }

  return junction?.type
}

export function addVariablesToName(name, stateSectorJunction) {
  const variables = stateSectorJunction?.variables

  return variables
    ? `${name}, ${Object.entries(variables).map(([key, value]) => `${key} = ${value}`).join(', ')}`
    : name
}

export function getJunctionName(stateSectorJunction, junctionName, stateSectorJunctions, allJunctions) {
  if (stateSectorJunction?.isCustom) {
    return stateSectorJunction?.variables
      ? addVariablesToName(stateSectorJunction?.customJunctionName, stateSectorJunction)
      : stateSectorJunction?.customJunctionName
  }

  const name = findJunctionOriginalName(junctionName, stateSectorJunctions, allJunctions)

  return stateSectorJunction?.variables ? addVariablesToName(name, stateSectorJunction) : name
}
